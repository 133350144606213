import { AnalyticsBrowser } from '@segment/analytics-next';

//const analytics = AnalyticsBrowser.load({ writeKey: process.env.REACT_APP_SEGMENT_WRITE_KEY });
const analytics = AnalyticsBrowser.load({ writeKey: 'XbGyycyyxCQmwpfvpU4jfSZhBBd583sh' });

let userId = null;

export const setUserId = (id, orgDetails) => {
  userId = id;
  analytics.identify(id, orgDetails);
};

export const trackEvent = (event, properties = {}) => {
  analytics.track(event, properties);
};

export const trackPageView = (pageName, path, url) => {
  analytics.page(pageName, {
    path,
    url,
  });
};
