export enum CollectionVisibilityEnum {
    PRIVATE = 0,
    PUBLIC = 1
};

export enum CollectionOverViewMetricsCardTypeEnum {
    WEEKLY_METRICS = 'weekly_metrics',
    PERSONAS = 'personas',
    EVENTS = 'events',
    TOP_PEOPLE = 'top_people',
    NEGATIVE_QUERIES = "negative_queries"
}

export enum OverviewTabNameEnum {
    OVERVIEW = 'Overview',
    CRM = 'CRM'
};

export enum CollectionPageModeEnum {
    OVERVIEW = "overview",
    CRM = "crm",
    CONTENT = "content",
    REPORT = "report"
}

export enum CollectionThreeDotsMenuItemsEnum {
    ADD_NEW_CONTENT = 'Add New Content',
    SHARE_COLLECTION = 'Share Collection',
    COPY_URL = 'Copy URL',
    DELETE = 'Delete'
}

export enum ContentThreeDotsMenuItemsEnum {
    COPY_URL = 'Copy URL',
    VIEW_SUMMARY = 'View summary',
    DOWNLOAD_DOCUMENT = 'Download document',
    DELETE_FROM_COLLECTION = 'Delete from collection',
    DELETE = 'Delete'
}

export enum DeleteModesEnum {
    DELETE_COLLECTION = 'delete_collection',
    DELETE_CONTENT = 'delete_content',
    DELETE_CONTENT_FROM_COLLECTION = 'delete_content_from_collection'
}

export enum CollectionListSectionEnum {
    ALL = "ALL",
    STARRED = "STARRED"
}

export enum CollectionReportPageEnum {
    KNOWLEDGE_GAPS = "knowledge-gaps",
    AGENT_PERFORMANCE = "agent-performance"
}