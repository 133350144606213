import { ROUTE_ACCESS_DENIED, ROUTE_LOGIN, ROUTE_ONBOARDING, ROUTE_PASSWORD_RESET, ROUTE_SIGNUP, ROUTE_CALLBACK } from "./routes";

export const PUBLIC_ROUTES = [
    ROUTE_SIGNUP,
    ROUTE_LOGIN,
    ROUTE_PASSWORD_RESET,
    ROUTE_ACCESS_DENIED,
    ROUTE_CALLBACK
];

export const PUBLIC_ROUTES_WITH_ONE_PATH_PARAM_AT_END = [
    ROUTE_ONBOARDING
];

export const DEFAULT_NETWORK_NAME = 'CUCopilot'