export enum ModuleEnum {
    ECHO = 'echo',
    FETCH = 'fetch',
    INDEX = 'index'
};

export enum ToolEnum {
    PROFILE = 'profile',
    COLLECTIONS = 'collections',
    KNOWLEDGE = 'knowledge',
    METRICS = 'metrics',
    INTERACTIVE_METRICS = 'interactive-metrics',
    SETTINGS = 'settings',
    ORG_SETTINGS = 'org-settings',
    CHAT_ROOM = 'chat-room',
    EVENTS = 'events',
    KNOWLEDGE_GAP_REPORT = 'knowledge-gap-report'
};

export enum ModeTypesEnum {
    ORG_LEVEL = 0,
    NERWORK_LEVEL = 1
}