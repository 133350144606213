import { useState, useEffect } from "react";
import { RiCloseLine } from 'react-icons/ri';
import styles from './FetchFilters.module.css';

const FetchFilters = ({ onClose, filters, selectedFilters, setSelectedFilters }) => {
    const [tempFilters, setTempFilters] = useState(selectedFilters);

    useEffect(() => {
        setTempFilters(selectedFilters);
    }, [selectedFilters]);

    // Categories are now open by default
    const [isCategoryCollapsed, setIsCategoryCollapsed] = useState({
        sentiments: false,
        emotions: false,
        purposes: false,
        reps: false
    });

    const handleReset = () => {
        setTempFilters(selectedFilters);
    };

    const toggleCategory = (category) => {
        setIsCategoryCollapsed(prevState => ({
            ...prevState,
            [category]: !prevState[category]
        }));
    };

    const handleDateChange = (dateType, value) => {
        setTempFilters(prevState => ({
            ...prevState,
            [dateType]: value
        }));
    };

    const handleFilterClick = (category, value) => {
        setTempFilters(prevState => ({
            ...prevState,
            [category]: prevState[category].includes(value)
                ? prevState[category].filter(item => item !== value)
                : [...prevState[category], value]
        }));
    };

    const handleSelectAll = category => {
        setTempFilters(prevState => ({
            ...prevState,
            [category]: filters[category]
        }));
    };

    const handleClear = category => {
        setTempFilters(prevState => ({
            ...prevState,
            [category]: []
        }));
    };

    const handleApplyClick = () => {
        setSelectedFilters(tempFilters);
        onClose();
    };

    return (
        <div className={styles.filterModal}>
            <div className={styles.modalCloseButton}>
                <RiCloseLine onClick={onClose} size="45px" />
            </div>
            <div className={styles.filtersHeader}>
                <h2>Filters</h2>
                <button className={styles.resetButton} onClick={handleReset}>Reset</button>
            </div>
            <div className={styles.filterOptions} >
                <h4 className={styles.filterHeader}>Dates</h4>
                <div className={styles.dateSelectors}>
                    <label className={styles.dateLabel}>
                        From:
                        <input
                            className={styles.dateInput}
                            type="date"
                            value={tempFilters.startDate}
                            onChange={(e) => handleDateChange('startDate', e.target.value)}
                        />
                    </label>
                    <label className={styles.dateLabel}>
                        To:
                        <input
                            className={styles.dateInput}
                            type="date"
                            value={tempFilters.endDate}
                            onChange={(e) => handleDateChange('endDate', e.target.value)}
                        />
                    </label>
                </div>
                {Object.keys(filters).map(category => (
                    <div key={category} className={styles.filterCategory}>
                        <div key={category} className={styles.filterHeader}>
                            <h4 className={styles.filterHeader} onClick={() => toggleCategory(category)}>{category}</h4>
                            <button className={styles.selectAll} onClick={() => handleSelectAll(category)}>All</button>
                            <button className={styles.selectAll} onClick={() => handleClear(category)}>Clear</button>
                        </div>
                        {!isCategoryCollapsed[category] && (
                            <div className={styles.filterSelect}>
                                {filters[category].map(value => {
                                    const displayValue = value === null ? "Not specified" : value === "" ? "Blank" : value;
                                    return (
                                        <div className={`${styles.filterList} ${tempFilters[category].includes(value) ? styles.selected : ''}`} key={String(value)} onClick={() => handleFilterClick(category, value)}>
                                            <label className={styles.customCheckbox}>{displayValue}</label>
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                    </div>
                ))}
            </div>
            <button className={`${styles.applyFilters} bg-primary-color`} onClick={handleApplyClick}>Apply</button>
        </div>
    );
};

export default FetchFilters;
